declare function fetch(url: string, params: any);

export const HTTP_STATUS = {
   OK: 200,
   CREATED: 201,
   UNAUTHORIZED: 401,
   FORBIDEN: 403,
   NOT_FOUND: 404,
   METHOD_NOT_ALOWED: 405,
   UNSUPORTED_MEDIA_TYPE: 415,
   SERVER_ERROR: 500
}

export class Request {
    /*
     *
     *  Base api requests
     *
     */
    static base(url: string, method: string, headers: any = null, data: any = null, forceContentType: boolean = true) {
        let params = {
            method,
            credentials:'same-origin',
            headers: {
                ...headers
            }
        };

        if (method !== 'GET' || forceContentType) {
            params.headers['Content-Type'] = 'application/json';
        }

        if (data) {
            params['body'] = JSON.stringify(data);
        }

        let res = {
            status: null,
            data: null
        };

        return fetch(url,params).then((response) => {
            res.status = response.status;
            return response.json();
        }).then((response) => {
            res.data = response;
            return res;
        });
    }

    static get(url, headers = null, forceContentType: boolean = true) {
        return Request.base(url, 'GET', headers, null, forceContentType);
    }

    static post(url, headers = null, data = {}, forceContentType: boolean = true) {
        return Request.base(url, 'POST', headers, data, forceContentType);
    }

    static put(url, headers = null, data = {}, forceContentType: boolean = true) {
        return Request.base(url, 'PUT', headers, data, forceContentType);
    }

    static delete(url, headers = null, data = {}, forceContentType: boolean = true) {
        return Request.base(url, 'DELETE', headers, data, forceContentType);
    }
}

// Keep types the same, but make each property to be read-only.
export interface ApiError {
    code?: string;
    message?: string;
}

export interface Response<T> {
    status: number;
    data: T & ApiError;
}