import * as React from 'react';

/**************** Component props ***************/
interface ComponentProps extends React.Props<React.Component> {
    small?: boolean;
}

/**************** Component ***************/
const component = (props: ComponentProps) => {
    return (
        <table className={'keyValue-table' + (props.small ? ' small' : '')}>
            <tbody>{props.children}</tbody>
        </table>
    );
};

/**************** ValuePair props ***************/
interface ValuePairProps extends React.Props<React.Component> {
    name: string;
}

/**************** ValuePair ***************/
export const ValuePair = (props: ValuePairProps) => {
    return (
        <tr>
            <td className='keyValue-table-name'>{props.name}</td>
            <td className='keyValue-table-value'>{props.children}</td>
        </tr>
    );
};

export default component;
