import * as React from 'react';
import { Container } from '../Components/Container';
import { PhotoContainer } from '../Containers/PhotoContainer';
import { JSONEditor } from '../Components/JSONEditor';
import { isString } from '../../helpers/typechecks';

export interface FilesProps {
    visible: boolean;
    onePage: boolean;
    args?: any;
    fields: any[];
    onComplete: (files: any, args: any) => void;
}

export class Files extends React.Component<FilesProps> {
    public readonly state = {
        fileIndex: 0,
        files: {},
        args: {},
    };

    public UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.visible !== this.props.visible) {
            this.setState({
                fileIndex: 0,
                files: {},
            });
        }
    }

    /**
     * For multipages
     */
    protected next = () => {
        this.setState({
            fileIndex: this.state.fileIndex + 1,
        }, () => {
            if (this.state.fileIndex >= this.props.fields.length) {
                this.props.onComplete(this.state.files, this.state.args);
            }
        });
    }

    protected previous = () => {
        this.setState({
            fileIndex: Math.max(this.state.fileIndex - 1, 0),
        });
    }

    /**
     * Image selected event
     */
    protected onArgsChanged = (value) => {
        this.setState({
            args: value,
        });
    }

    /**
     * Image selected event
     */
    protected onImageSelected = (name, image) => {
        this.setState({
            files: {
                ...this.state.files,
                [name]: image,
            },
        });
    }

    /**
     * Fields complete
     */
    protected onComplete = () => {
        if (this.getValid()) {
            if (this.props.onePage) {
                this.props.onComplete(this.state.files, this.state.args);
            } else {
                this.next();
            }
        }
    }

    /**
     * True if form is valid
     */
    protected getValid = () => {
        if (this.props.onePage) {
            return this.props.fields.length === Object.keys(this.state.files).length;
        } else {
            return this.state.files.hasOwnProperty(this.props.fields[this.state.fileIndex].field);
        }
    }

    /**
     * Render
     */
    public render() {
        let args = this.props.args;
        if (isString(args)) {
            try {
                args = JSON.parse(args);
            } catch (e) {
                console.log(this.props.args);
                console.log(e);
                args = null;
            }
        }
        return (
            <Container visible={this.props.visible}>
                {
                    args ? (
                        <React.Fragment>
                            <h2>{args.title ? args.title : 'Flow arguments'}</h2>
                            <JSONEditor
                                schema={args}
                                onChange={this.onArgsChanged}
                            />
                        </React.Fragment>
                    ) : null
                }
                {
                    this.props.fields.map((field, index) => (
                        <div key={'field-' + field.field}>
                            <h2>{field.header}</h2>
                            <PhotoContainer
                                icon={field.icon}
                                text={field.text}
                                visible={(this.state.fileIndex === index || this.props.onePage) && this.props.visible}
                                onImageSelected={this.onImageSelected.bind(this, field.field)}
                            />
                        </div>
                    ))
                }
                <div>
                    <a className={'button'} onClick={this.onComplete}>Upload</a>
                </div>
            </Container>
        )
    }
}
