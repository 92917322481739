import * as React from 'react';
import { isArray } from '../../helpers/typechecks';

interface HeaderProps {
    configuration: string;
    configurations: any;
    onChange: (flowName: string) => void;
    showSelect: boolean;
    options?: string[];
}

export const Header = (props: HeaderProps) => {
    const allKeys = Object.keys(props.configurations).filter((key) => {
        return !isArray(props.configurations[key].selectOptions);
    });

    const options = isArray(props.options) ? props.options : allKeys;
    return (
        <div className='header'>
            <div className='logo' />
            <div className='container'>
                {
                    props.showSelect ? (
                        <div className="select-style">
                            <select onChange={(e) => props.onChange(e.target.value)} value={props.configuration}>
                                {
                                    options.map((configurationName) => (
                                        <option value={configurationName} key={'select-flow-' + configurationName}>{props.configurations[configurationName].name}</option>
                                    ))
                                }
                            </select>
                        </div>
                    ) : null
                }
            </div>
        </div>
    )
};
