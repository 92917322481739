import * as React from 'react';
import * as classNames from 'classnames';
import Dropzone from 'react-dropzone';
import { IconProp, library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAlt, faIdCard } from '@fortawesome/free-solid-svg-icons';

library.add(faUserAlt);
library.add(faIdCard);

export interface FileDropzoneProps {
    text: string;
    onImageSelected: (file) => void;
    icon?: IconProp;
}

export class FileDropzone extends React.Component<FileDropzoneProps> {
    protected onDrop = (acceptedFiles, rejectedFiles) => {
        this.props.onImageSelected(acceptedFiles[0]);
    }

    public render() {
        return (
            <Dropzone onDrop={this.onDrop}>
                {({getRootProps, getInputProps, isDragActive}) => {
                    return (
                        <div
                            {...getRootProps()}
                            className={classNames('filedropzone', {'isActive': isDragActive})}
                        >
                            <input {...getInputProps()} />
                            <div className='text-container'>
                                {
                                    this.props.icon ? <FontAwesomeIcon color='#9d9d9d' size='3x' icon={this.props.icon}/> : null
                                }
                                {
                                    isDragActive ?
                                        <p>Drop file here</p> :
                                        <p>{this.props.text}</p>
                                }
                            </div>
                        </div>
                    )
                }}
            </Dropzone>
        );
    }
}