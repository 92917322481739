import * as React from 'react';
import { getImage } from '../../helpers/api';

export interface ImageProps {
    url: string;
    apiKey: string;
    error?: string;
}

export interface ImageState {
    image: any;
}

export class Image extends React.Component<ImageProps, ImageState> {

    protected componentIsMounted = false;

    constructor(props: ImageProps) {
        super(props);

        this.state = {
            image: null
        };

        this.loadImage(props.url);
    }

    public componentDidMount() { 
        this.componentIsMounted = true;
    }
    
    public componentWillUnmount() {
        this.componentIsMounted = false;
    }

    public UNSAFE_componentWillReceiveProps(nextProps: ImageProps) {
        if (nextProps.url !== this.props.url) {
            this.loadImage(nextProps.url);
        }
    }

    protected loadImage(url) {
        if (url) {
            getImage(this.props.apiKey, 'jpeg', url)
                .then((data) => {
                    if (this.componentIsMounted) {
                        this.setState({
                            image: data
                        });
                    }
                });
        } else {
            if (this.componentIsMounted) {
                this.setState({
                    image: null
                });
            }
        }
    }

    public render() {
        const ratio = Math.min(this.state.image ? this.state.image.height / this.state.image.width : 0.5, 0.65);
        const wrapperStyle = {
            paddingBottom: (ratio * 100) + '%',
            border: this.props.error ? '3px solid red' : undefined
        };
        const imageStyle = {
            opacity: this.state.image ? 1 : 0,
        };
        return (
            <div className='imgWraper' style={wrapperStyle}>
                {!this.state.image ? <div className='loader'></div> : null}
                <img src={this.state.image ? this.state.image.url : null} style={imageStyle}/>
                {
                    this.props.error ? <p>{this.props.error}</p> : null
                }
            </div>
        )
    }
}
