import * as React from 'react';
import { Container } from '../Components/Container';
import { UploadContainer } from '../Containers/UploadContainer';
import { ApiConfiguration } from '../../helpers/api';

export interface UploadFilesProps {
    visible: boolean;
    fields: any[];
    files: any;
    onComplete: () => void;
    onError: (err) => void;
    token: string;
    flow: string;
    configuration: ApiConfiguration;
}

export class UploadFiles extends React.Component<UploadFilesProps> {
    public readonly state = {
        fileIndex: 0,
    };

    public UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.visible !== this.props.visible) {
            if (nextProps.fields.length === 0) {
                this.props.onComplete();
                return;
            }
            this.setState({
                fileIndex: 0,
            });
        }
    }

    /**
     * Next image please
     */
    protected next = () => {
        this.setState({
            fileIndex: this.state.fileIndex + 1,
        }, () => {
            if (this.state.fileIndex >= this.props.fields.length) {
                this.onComplete();
            }
        });
    }

    /**
     * Upload complete
     */
    protected onComplete = () => {
        this.props.onComplete();
    }

    /**
     * Render
     */
    public render() {
        return (
            <Container visible={this.props.visible}>
                {
                    this.props.fields.map((field, index) => (
                        <UploadContainer
                            flow={this.props.flow}
                            token={this.props.token}
                            onError={this.props.onError}
                            onUploaded={this.next}
                            text={field.uploadingText}
                            file={this.props.files[field.field]}
                            propertyName={field.field}
                            visible={this.state.fileIndex === index && this.props.visible}
                            key={'upload-' + field.field}
                            configuration={this.props.configuration}
                        />
                    ))
                }
            </Container>
        )
    }
}
