import * as React from 'react';
import { getToken as apiGetToken, ApiConfiguration } from '../../helpers/api';
import { WaitingFor } from './WaitingFor';
import { Container } from './Container';

export interface GetTokenProps {
    onToken: (token: string) => void;
    onError: (error: string) => void;
    flow: string;
    visible: boolean;
    configuration: ApiConfiguration;
    args: any;
}

export class GetToken extends React.Component<GetTokenProps> {

    public componentDidMount() {
        if (this.props.visible) {
            this.getToken(this.props);
        }
    }

    public UNSAFE_componentWillReceiveProps(nextProps: GetTokenProps) {
        if (nextProps.visible && !this.props.visible) {
            this.getToken(nextProps);
        }
    }

    public render() {
        return (
            <Container visible={this.props.visible}>
                <WaitingFor text={'Just moment please.'} />
            </Container>
        )
    }

    protected getToken = (props: GetTokenProps) => {
        apiGetToken(this.props.configuration, props.flow, props.args)
            .then((token) => {
                this.props.onToken(token);
            }).catch((error) => {
                this.props.onError(error.message);
            });
    }
}

