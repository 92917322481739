import * as React from 'react';
import { Container } from '../Components/Container';
import { FileUploader } from '../Components/FileUploader';
import { getFileUploadUrl, ApiConfiguration } from '../../helpers/api';

export interface UploadContainerProps {
    text: string;
    visible: boolean;
    onUploaded: () => void;
    onError: (e: any) => void;
    file: any;
    flow: string;
    token: string;
    propertyName: string;
    configuration: ApiConfiguration;
}

export class UploadContainer extends React.Component<UploadContainerProps> {
    public render() {
        return (
            <Container visible={this.props.visible}>
                <div className='containerSmallIn'>
                    <h2>{this.props.text}</h2>
                    <FileUploader
                        onUploaded={this.props.onUploaded}
                        onError={this.props.onError}
                        file={this.props.visible ? this.props.file : null}
                        configuration={this.props.configuration}
                        url={getFileUploadUrl(this.props.configuration, this.props.token, this.props.propertyName, this.props.flow)} />
                </div>
            </Container>
        )
    }
}
