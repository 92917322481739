import * as React from 'react';
import { Image } from './Image';
import { safe } from '../../helpers/safe';
import { isObject, isBoolean, isNumber, isArray, isString } from '../../helpers/typechecks';
import KeyValue, { ValuePair } from '../Components/KeyValue';
import { getByExpression } from '../../helpers/expression';
import ReactJson from 'react-json-view';

export interface ResultProps {
    result: any;
    apiKey: string;
    onAgain: () => void;
    configuration: string;
    configurations: any;
    token: string;
}

export interface ResultState {}

export class Result extends React.Component<ResultProps, ResultState> {
    constructor(props: ResultProps) {
        super(props);

        this.state = {};
    }

    protected getConfiguration = () => {
        return this.props.configurations[this.props.configuration];
    }

    protected translateKey(key: string): string {
        const configuration = this.getConfiguration();
        if (configuration.translates) {
            if (configuration.translates.hasOwnProperty(key)) {
                return configuration.translates[key];
            }
        }
        return key;
    }

    protected translateProperties(object: Object) : Object {
        const ret = {};

        for(let i in object) {
            const val = (object[i] && typeof object[i] === 'object') ? this.translateProperties(object[i]) : object[i];
            ret[this.translateKey(i) || i] = val;
        }

        return ret;
    }

    protected goAgain = () => {
        this.props.onAgain();
    }

    protected downloadAsJson = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        const content = JSON.stringify(this.props.result);
        const data = encodeURI('data:text/csv;charset=utf-8,' + content);
        const link = document.createElement('a');
        link.setAttribute('href', data);
        link.setAttribute('download', 'results.json');
        link.click();
    }

    protected renderMatch = (schema: any) => {
        const configuration = this.getConfiguration();
        const flowSchema = configuration.flowSchema;
        const sourceName = schema.sourceName;

        let matchPercentage;
        let selfieData;
        if (sourceName) {
            matchPercentage = safe(() => Math.round(this.props.result.match[sourceName]), null);
            selfieData= safe(() => this.props.result.selfie[sourceName], null);
        } else {
            matchPercentage = safe(() => Math.round(this.props.result.match), null);
            selfieData= safe(() => this.props.result.selfie, null);
        }

        return (
            <div className='results-match-images' key={'match-result-' + sourceName}>
                {schema.title ? <h2>{schema.title}</h2> : null}
                <div className='clear' />
                <div>
                    <div className='match-image'>
                        <Image
                            url={this.props.result[schema.leftSide.key]}
                            apiKey={this.props.apiKey}
                        />
                    </div>
                    <div className='match-image right'>
                        <Image
                            url={this.props.result[schema.rightSide.key]}
                            apiKey={this.props.apiKey}
                        />
                    </div>
                    <div className='compare'>
                        <div className={(matchPercentage && matchPercentage > 50) ? 'success' : 'failed'}>
                            {matchPercentage ? 'Similarity' : 'No match'}
                        </div>

                        {matchPercentage ? <div className='percentage'>{matchPercentage + '%'}</div> : null}
                        {
                            (selfieData && isArray(schema.values)) ? (
                                <KeyValue small>
                                    {
                                        schema.values.map((value) => (
                                            <ValuePair key={'selfie-key-' + value} name={this.translateKey(value)}>
                                                {this.renderValue(flowSchema, selfieData, value)}
                                            </ValuePair>
                                        ))
                                    }
                                </KeyValue>
                            ): null
                        }
                    </div>
                </div>
                <div className='clear' />
            </div>
        );
    }

    public render() {
        if (!this.props.result) {
            return null;
        }

        const configuration = this.getConfiguration();
        const flowSchema = configuration.flowSchema;

        const images = (flowSchema.results && isArray(flowSchema.results.images)) ? flowSchema.results.images : [];
        const imagesUrls = images.map((image) => {
            const url = getByExpression(this.props.result, image.key);
            if (url) {
                return {
                    url,
                    key: image.key,
                };
            }
            return null;
        }).filter((url) => url !== null);

        const outputs = (flowSchema.results && isArray(flowSchema.results.outputs)) ? flowSchema.results.outputs : [{title: 'Results'}];

        return (
            <div className='result'>
                <div>
                    {
                        (flowSchema.results && flowSchema.results.match) ? (
                            isArray(flowSchema.results.match) ? (
                                flowSchema.results.match.map((schema) => (
                                    this.renderMatch(schema)
                                ))
                            ) : (
                                this.renderMatch(flowSchema.results.match)
                            )
                        ): null
                    }
                    {
                        imagesUrls.map((image) => (
                            <div className='spaced-image' key={'image-' + image.key}>
                                <Image
                                    url={image.url}
                                    apiKey={this.props.apiKey}
                                />
                            </div>
                        ))
                    }
                    {
                        (flowSchema.results && isArray(flowSchema.results.title)) ? (
                            <div className='json'>
                                <h2>{flowSchema.results.title}</h2>
                            </div>
                        ) : null
                    }
                    {
                        flowSchema.results ? (
                            outputs.map((output) => (
                                this.renderOutput(output, flowSchema)
                            ))
                        ): null
                    }
                    {
                        !flowSchema.results ? (
                            <ReactJson src={this.props.result} />
                        ) : null
                    }
                    {
                        (!flowSchema.results || (flowSchema.results && flowSchema.results.showToken)) ? (
                            <div className='results-token'>{this.props.token}</div>
                        ) : null
                    }
                </div>
                <div>
                    <a className='buttonSecondary minWidth' onClick={this.downloadAsJson}>Download</a>
                    <a className='button minWidth' onClick={this.goAgain}>Try it again</a>
                </div>
            </div>
        )
    }

    protected renderOutput(output: any, flowSchema: any) {
        const values = isArray(output.values) ? output.values : Object.keys(this.props.result);

        return (
            <div className='json' key={'data-block-' + output.title}>
                <h2>{output.title}</h2>
                <KeyValue>
                    {
                        values.map((value) => (
                            <ValuePair key={'value-key-' + value} name={this.translateKey(value)}>
                                {this.renderValue(flowSchema, this.props.result, value)}
                            </ValuePair>
                        ))
                    }
                </KeyValue>
            </div>
        );
    }

    protected formatNumbers = (formaters: any[], key: string, data: any): string => {
        let output = null;
        if (isNumber(data)) {
            for(const formater of formaters) {
                if (formater.keys.indexOf(key) !== -1) {

                    if (output === null) {
                        output = data;
                    }

                    if (isNumber(formater.multiply)) {
                        output = output * formater.multiply;
                    }

                    if (formater.round) {
                        output = Math.round(output);
                    }

                    if (formater.postfix) {
                        output = output + formater.postfix;
                    }
                }
            }
        }
        return output;
    }

    protected renderValue = (flowSchema, data, key) => {
        const d = getByExpression(data, key);

        let formatedOutput = null;
        if (flowSchema.results && flowSchema.results.numericFormats && (formatedOutput = this.formatNumbers(flowSchema.results.numericFormats, key, d))) {
            return formatedOutput;
        }

        if (isBoolean(d)) {
            return d ? 'Yes' : 'No';
        } else if (isString(d)) {
            return d;
        } else if (isNumber(d)) {
            return d;
        } else if (isObject(d) && isNumber((d as any).low) && isNumber((d as any).high)) {
            return (d as any).low + ' - ' + (d as any).high;
        }

        return 'N/A';
    }
}
