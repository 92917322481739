import * as React from 'react';

export interface WaitingForProps {
    text: string;
}
export const WaitingFor = (props: WaitingForProps) => {
    return (
        <div className='waitingFor'>
            <div className="loader"></div>
            <p>{props.text}</p>
        </div>
    )
};

