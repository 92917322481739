/**
 * Check if reference is to object and value is not null
 *
 * @param ref Reference to value
 * @returns true if ref is object
 */
export function isObject(ref: any): ref is object {
    return typeof ref === 'object' && ref !== null;
}

/**
 * Check if refence is to string.
 *
 * @param ref Reference to value
 * @returns true if ref is string
 */
export function isString(ref: any): ref is string {
    return typeof ref === 'string';
}

/**
 * Check if refence is to number.
 *
 * @param ref Reference to value
 * @returns true if ref is number
 */
export function isNumber(ref: any): ref is number {
    return typeof ref === 'number';
}

/**
 * Check if refence is to boolean.
 *
 * @param ref Reference to value
 * @returns true if ref is boolean
 */
export function isBoolean(ref: any): ref is boolean {
    return typeof ref === 'boolean';
}

/**
 * Check if reference is to object that is instance of date.
 *
 * @param ref Reference to value
 * @returns true if ref is instance of date
 */
export function isDate(ref: any): ref is Date {
    return ref instanceof Date;
}

/**
 * Check if reference is array
 *
 * @param ref Reference to value
 * @returns true if ref is array
 */
export function isArray<T>(ref: T[]): ref is T[] {
    return Array.isArray(ref);
}

/*
* Complex validators
*/

/**
 * Check object, if include all keys from keys array.
 *
 * @param ref Reference to object
 * @param keys Array of keys
 * @returns true if object does not contains all keys
 */
export function objectHasKeys(ref: object, keys: string[]): ref is object {
    const objectKeys = Object.keys(ref);
    return keys.every((value) => objectKeys.indexOf(value) >= 0);
}
