import * as React from 'react';
import { ApiConfiguration } from '../../helpers/api';

export interface FileUploaderProps {
    file: any;
    url: string;
    onError: (e: string) => void;
    onUploaded: () => void;
    configuration: ApiConfiguration;
}

export interface FileUploaderState {
    progress: number;
}

export class FileUploader extends React.Component<FileUploaderProps, FileUploaderState> {
    constructor(props: FileUploaderProps) {
        super(props);

        this.state = {
            progress: 0,
        };

        if (props.file) {
            this.send(props);
        }
    }

    public UNSAFE_componentWillReceiveProps(nextProps: FileUploaderProps) {
        if (nextProps.file !== this.props.file) {
            if (nextProps.file) {
                this.send(nextProps);
            } else {
                this.setState({
                    progress: 0
                });
            }
        }
    }

    protected send = (props) => {
        const formData = new FormData();
        formData.append('file', props.file);

        const request = new XMLHttpRequest();
        request.open('POST', props.url);
        request.setRequestHeader('api-key', this.props.configuration.apiKey);

        request.addEventListener('load', (e) => {
            if (request.status >= 200 && request.status <= 299) {
                this.props.onUploaded();
            } else {
                if (request.response) {
                    let json;
                    try {
                        json = JSON.parse(request.response);
                    } catch(e) {}

                    if (json) {
                        if (json.error && json.error.message) {
                            this.props.onError(json.error.message);
                            return;
                        }
                    }
                }
                this.props.onError('We are sorry, but there is some error on server error, try it later plase.');
            }
        }, false);

        request.addEventListener('error', (e) => {
            this.props.onError('We are sorry, but some error occured while uploading image, try it later plase.');
          }, false);

        request.upload.addEventListener('progress', (e) => {
            let progress = 0;
            if (e.total !== 0) {
                progress = (e.loaded / e.total) * 100;
            }
            this.setState({
              progress,
            });
          }, false);

        request.addEventListener('abort', (e) => {
            this.setState({
                progress: 0,
            });
        }, false);

        request.send(formData);
    }

    public render() {
        return (
            <div>
                <div className='progressbar'>
                    <div className='inner' style={{width: this.state.progress + '%'}}></div>
                </div>
                <p>{Math.round(this.state.progress) + '%'}</p>
            </div>
        )
    }
}
