/**
 * Copy object without property
 * @param obj
 * @param omitKey
 */
export function omit<T>(obj: T, omitKeys: string[]): Partial<T> {
    return Object.keys(obj).reduce((result, key) => {
      if (omitKeys.indexOf(key) === -1) {
         result[key] = obj[key];
      }
      return result;
    }, {} as Partial<T>);
}