import * as React from 'react';
import { Container } from '../Components/Container';
import { FileDropzone } from '../Components/FileDropZone';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TakePhoto } from '../Components/TakePhoto';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface PhotoContainerProps {
    text: string;
    visible: boolean;
    icon?: IconProp;
    onImageSelected: (image: any) => void;
}

export class PhotoContainer extends React.Component<PhotoContainerProps> {
    public readonly state = {
        dialogOpened: false,
        file: null,
        url: null,
    };

    public UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.visible !== this.props.visible) {
            this.setState({
                dialogOpened: false,
                file: null,
                url: null,
            });
        }
    }

    protected onTakePhoto = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({dialogOpened: true});
    }

    protected onCancel = () => {
        this.setState({dialogOpened: false});
    }

    protected onImageSelected = (file: any) => {
        this.setState({
            dialogOpened: false,
            file,
            url: URL.createObjectURL(file),
        }, () => {
            this.props.onImageSelected(this.state.file);
        });
    }

    protected onReset = () => {
        this.setState({
            dialogOpened: false,
            file: null,
        });
    }

    public render() {
        const style = {
            zIndex: this.state.dialogOpened ? 200 : 0,
        };
    
        return (
            <Container visible={this.props.visible} style={style}>
                {
                    !this.state.file ? (
                        <div className='photoContainer'>
                            <FileDropzone icon={this.props.icon} onImageSelected={this.onImageSelected} text={this.props.text}/>
                            {
                                !isMobile ? (
                                    <div>
                                        <p>or</p>
                                        <a onClick={this.onTakePhoto} className='button buttonSecondary'><FontAwesomeIcon icon={faCamera}/>Take photo</a>
                                        {
                                            this.state.dialogOpened && <TakePhoto onClose={this.onCancel} onPhotoTaken={this.onImageSelected}/>
                                        }
                                    </div>
                                ) : null
                            }
                        </div>
                    ) : null
                }
                {
                    this.state.file ? (
                        <div className='photoContainer'>
                            <div className='imgWrapper'>
                                <img src={this.state.url} />
                            </div>
                            <div>
                                <a className='button buttonSecondary' onClick={this.onReset}>Try again</a>
                            </div>
                        </div>
                    ) : null
                }
            </Container>
        )
    }
}
