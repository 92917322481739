import { Request } from "./Request";
import {default as globalConfig} from 'config';
import { isObject } from "./typechecks";

export interface FieldState {
    type: string;
    key: string;
    valid: boolean;
    errors: string[];
}

export interface State {
    finished: boolean;
    fields: FieldState[];
    hasError: boolean;
}


export interface ApiConfiguration {
    client: string;
    apiKey: string;
}


export async function getToken(config: ApiConfiguration, flowName: string, args?: any): Promise<string> {
    const result = await Request.post(globalConfig.serverUrl + '/' + config.client + '/' + flowName, {'api-key': config.apiKey}, args);
    if (result.status === 201 && result.data && result.data.request) {
        return result.data.request.token;
    } else {
        if (isObject(result.data) && isObject(result.data.error) && result.data.error.code === 40012) {
            throw new Error('You must agree with Terms and Conditions');
        }
        throw new Error('We are sorry, but creating session failed. Try it again later please.');
    }
}

export async function getResult(config: ApiConfiguration, token: string, flowName: string): Promise<any> {
    const result = await Request.get(globalConfig.serverUrl + '/' + config.client + '/' + flowName + '/' + token + '/result', {'api-key': config.apiKey});
    if (result.status === 200 && result.data && result.data.result) {
        return result.data.result;
    } else {
        throw new Error('Getting result failed with status ' + result.status);
    }
}

export function getFileUploadUrl(config: ApiConfiguration, token: string, fieldName: string, flowName: string): string {
    return globalConfig.serverUrl + '/' + config.client + '/' + flowName + '/' + token + '/files/' + fieldName;
}

export async function getState(config: ApiConfiguration, token: string, flowName: string): Promise<State> {
    const result = await Request.get(globalConfig.serverUrl + '/' + config.client + '/' + flowName + '/' + token + '/state', {'api-key': config.apiKey});
    if (result.status === 200 && result.data && result.data.state) {
        return result.data.state;
    } else {
        throw new Error('Getting state failed with status ' + result.status);
    }
}

export async function getImage(apiKey: string, type: string, url: string) {
    const options = {
        method: 'GET',
        headers: {'api-key': apiKey}
    };

    return fetch(url, options)
        .then((response) => response.blob())
        .then((blob) => {
            const url = URL.createObjectURL(blob);
            return new Promise((resolve: (result: any) => void, reject: () => void) => {
                const img = new Image();
                img.src = url;
                img.onload = () => resolve({
                    width: img.width,
                    height: img.height,
                    url,
                });
            });
        })
        .catch((e) => {})
}

export async function getFile(folder: string, name: string) {
    const options = {
        method: 'GET',
    };
    return fetch( globalConfig.serverUrl + '/public/' + folder + '/' + name, options)
        .then((response) => {
            if (response.status === 200) {
                return response.text();
            } else {
                throw new Error('Getting config failed');
            }
        })
        .catch((e) => {})
}

export function arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = [].slice.call(new Uint8Array(buffer));
  
    bytes.forEach((b) => binary += String.fromCharCode(b));
  
    return window.btoa(binary);
}