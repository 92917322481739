import { replaceAll } from './string';
import { omit } from '../helpers/object';

export default class ConfigProcessor {
    public static prepareConfig(config: string): any {
        try {
            const json = JSON.parse(config);

            const references = Object.keys(json.$references)
                .map((refName) => {
                    return {
                        name: refName,
                        content: JSON.stringify(json.$references[refName]),
                    };
                });

            let configurationsString = JSON.stringify(json.configurations);

            references
                .forEach((reference) => {
                    configurationsString = replaceAll(configurationsString, '"\\' + reference.name + '"', reference.content);
                });

            const configurations = JSON.parse(configurationsString);
            return {
                ...omit(json, ['$references']),
                configurations,
            }
        } catch(e) {
            console.error(e);
            alert('We can`t parse configuration. Please contact Zipe administrator.')
        }
    }
}
