import * as React from 'react';
import { Header } from './Components/Header';
import { Landing } from './Components/Landing';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Result } from './Components/Result';
import { Container } from './Components/Container';
import { WaiterContainer } from './Containers/WaiterContainer';
import { GetToken } from './Components/GetToken';
import { Files } from './Steps/Files';
import { UploadFiles } from './Steps/UploadFiles';
import { isArray, isString, isBoolean } from '../helpers/typechecks';
import ConfigProcessor from '../helpers/ConfigProcessor';
import { getFile } from '../helpers/api';
import {default as globalConfig} from 'config';
import * as queryString from 'query-string';
import * as TagManager from 'react-gtm-module';

export interface AppState {
    style: string;
    config: any;
    args: any;
    files: {[field: string]: any};
    token: string;
    error: string;
    filesSelectComplete: boolean;
    filesUploadComplete: boolean;
    finished: boolean;
    result: any;
    selectedConfiguration: string;
    urlName: string;
}

export const STEP_GET_TOKEN = 'GET_TOKEN';
export const STEP_SELECT_FILES = 'STEP_SELECT_FILES';
export const STEP_UPLOAD_FILES = 'STEP_UPLOAD_FILES';
export const STEP_WAIT_FOR_RESULT = 'STEP_WAIT_FOR_RESULT';
export const STEP_RESULT = 'STEP_RESULT';

const defaultState = {
    files: {},
    args: {},
    error: null,
    filesSelectComplete: false,
    filesUploadComplete: false,
    finished: false,
    result: null,
};

export class App extends React.Component<{}, AppState> {

    constructor(props: {}) {
        super(props);
        let folder = globalConfig.serverFolder;

        const query = queryString.parse(location.search);
        if (query && query.folder) {
            folder = query.folder;
        }

        this.state = {
            style: globalConfig.serverUrl + '/public/' + folder + '/style.css',
            config: null,
            ...defaultState,
            token: null,
            selectedConfiguration: null,
            urlName: '',
        };

        getFile(folder, globalConfig.configFilename)
            .then((config) => {
                if (config) {
                    this.startConfiguration(ConfigProcessor.prepareConfig(config as string));
                }
            });
    }

    protected getQueryVariable(variable) {
        if (!window || !window.location || !window.location.search) {
            return null;
        }
        var query = window.location.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) == variable) {
                return decodeURIComponent(pair[1]);
            }
        }
        return null;
    }

    protected startConfiguration(config: any) {
        this.setState({
            config,
        }, () => {
            // suburl first
            const t = window.location.href.split('/');
            if (isString(t[3])) {
                if (this.tryToSetByName(t[3])) {
                    return;
                }
            }

            // try to find flow by first part in url
            const urlParts = window.location.hostname.split('.');
            if (urlParts.length > 1) {
                if (this.tryToSetByName(urlParts[0])) {
                    return;
                }
            }

            let loadedConfiguration = localStorage.getItem('selectedConfigurationV2');
            if (!this.state.config.configurations[loadedConfiguration]) {
                loadedConfiguration = null;
            }
            this.onConfigurationChange(loadedConfiguration ? loadedConfiguration : this.getDefaultConfiguration());
        });
    }

    protected tryToSetByName(name: string): boolean {
        let loadedConfiguration = localStorage.getItem(name + 'selectedConfigurationV2');
        let found = loadedConfiguration ? loadedConfiguration : null;
        if (!found) {
            found = this.findConfigurationByUrl(name);
        }

        if (found) {
            this.setState({
                urlName: name,
            }, () => {
                // change title
                this.correctTitle(found);
                this.onConfigurationChange(found);
            });
        }

        return !!found;
    }

    /**
     * Find flow by url name (flow must have name in flowNames array)
     * @param urlName 
     */
    protected findConfigurationByUrl(urlName: string): string {
        let found = null;
        Object.keys(this.state.config.configurations).forEach((flowName) => {
            const flow = this.state.config.configurations[flowName];
            if (isArray(flow.urlNames) && flow.urlNames.indexOf(urlName) !== -1) {
                found = flowName;
            }
        });
        return found;
    }

    /**
     * Get default flow
     * Default flow is flow, that has default : true in configuration
     */
    protected getDefaultConfiguration(): string {
        let found = null;
        Object.keys(this.state.config.configurations).forEach((configurationName) => {
            const configuration = this.state.config.configurations[configurationName];
            if (configuration.default) {
                found = configurationName;
            }
        });
        if (!found) {
            found = Object.keys(this.state.config.configurations)[0];
        }
        return found;
    }

    protected correctTitle = (configurationName) => {
        const configuration = this.state.config.configurations[configurationName];
        const title = configuration.title ? configuration.title : this.state.config.title;
        document.querySelector('title').innerText = title;
    }

    protected onAgain = (after?: () => void) => {
        this.setState({
            ...defaultState,
            token: null,
        }, after);
    }

    protected getCurrentApiConfiguration = (configuration) => {
        return {
            client: configuration.client ? configuration.client : this.state.config.client,
            apiKey: configuration.apiKey ? configuration.apiKey : this.state.config.apiKey,
        }
    }

    protected onConfigurationChange = (configurationName: string) => {
        this.setState({
            selectedConfiguration: configurationName
        }, () => {
            localStorage.setItem(this.state.urlName + 'selectedConfigurationV2', configurationName);
            this.correctTitle(configurationName);
            this.onAgain();
            // Init GTM
            if (this.state.config.configurations && this.state.config.configurations[this.state.selectedConfiguration]) {
                const configuration = this.state.config.configurations[this.state.selectedConfiguration];
                if (configuration.gtmId && TagManager && TagManager.initialize) {
                    TagManager.initialize({
                        gtmId: configuration.gtmId,
                    });
                }
            }
        });
    }

    protected onError = (e) => {
        this.setState({
            ...defaultState,
            error: e,
        });
    };

    protected onGlobalError = (e) => {
        this.onAgain(() => {
            this.onError(e);
        });
    };

    // flow handlers
    protected onFilesSelectComplete = (files, args) => {
        const source = this.getQueryVariable('s');
        this.setState({
            args: source ? {
                _source: source,
                ...(args || {})
            } : args,
            files,
            filesSelectComplete: true,
        })
    }

    protected onFilesUploadComplete = () => {
        this.setState({
            filesUploadComplete: true
        })
    }

    protected onStatusFinished = (result) => {
        this.setState({
            finished: true,
            error: null,
            result,
        });
    }

    protected onToken = (token: string) => {
        this.setState({
            token,
            error: null,
        });
    };

    protected onTokenError = (error: string) => {
        this.setState({
            token: null,
            error,
            filesSelectComplete: false,
        });
    };

    protected getStep() {
        if (!this.state.filesSelectComplete) return STEP_SELECT_FILES;
        else if (!this.state.token) return STEP_GET_TOKEN;
        else if (!this.state.filesUploadComplete) return STEP_UPLOAD_FILES;
        else if (!this.state.finished) return STEP_WAIT_FOR_RESULT;
        else return STEP_RESULT;
    }

    public render() {
        if (!this.state.selectedConfiguration || !this.state.config || !this.state.style) {
            return null;
        }

        const configuration = this.state.config.configurations[this.state.selectedConfiguration];
        const apiConfiguration = this.getCurrentApiConfiguration(configuration);
        const style = configuration.style ? configuration.style : this.state.config.style;
        const flowName = configuration.flowName ? configuration.flowName : this.state.config.flowName;
        const showSelect = isBoolean(configuration.showSelect) ? configuration.showSelect : this.state.config.showSelect;
        const showSelectOptions = configuration.selectOptions ? configuration.selectOptions : null;
        const landingHtmlText = configuration.htmlText ? configuration.htmlText : null;

        return (
            <div id="main" className={style}>
                {
                    configuration && !configuration.landing ? (
                        <React.Fragment>
                            <Header
                                configurations={this.state.config.configurations}
                                configuration={this.state.selectedConfiguration}
                                onChange={this.onConfigurationChange}
                                showSelect={showSelect}
                                options={showSelectOptions}
                            />
                            {
                                this.state.error ? <p className='error'><FontAwesomeIcon icon={faExclamationCircle}/>{this.state.error}</p> : null
                            }
                            <div className='mainContainer'>
                                {/* Step STEP_SELECT_FILES */}
                                <Files
                                    args={configuration.flowSchema.requirements.args}
                                    fields={configuration.flowSchema.requirements.fields}
                                    visible={this.getStep() === STEP_SELECT_FILES}
                                    onComplete={this.onFilesSelectComplete}
                                    onePage
                                />
                                {/* Step STEP_GET_TOKEN */}
                                <GetToken
                                    args={this.state.args}
                                    flow={flowName}
                                    onToken={this.onToken}
                                    onError={this.onTokenError}
                                    visible={this.getStep() === STEP_GET_TOKEN}
                                    configuration={apiConfiguration}
                                />
                                {/* Step STEP_UPLOAD_FILES */}
                                <UploadFiles
                                    flow={flowName}
                                    token={this.state.token}
                                    onError={this.onError}
                                    onComplete={this.onFilesUploadComplete}
                                    files={this.state.files}
                                    fields={configuration.flowSchema.requirements.fields}
                                    visible={this.getStep() === STEP_UPLOAD_FILES}
                                    configuration={apiConfiguration}
                                />
                                {/* Step STEP_WAIT_FOR_RESULT */}
                                <WaiterContainer
                                    visible={this.getStep() === STEP_WAIT_FOR_RESULT}
                                    token={this.state.token}
                                    onStatusFinished={this.onStatusFinished}
                                    onError={this.onError}
                                    onGlobalError={this.onGlobalError}

                                    flow={flowName}
                                    configuration={apiConfiguration}
                                />
                                {/* Step STEP_RESULT */}
                                <Container fullWidth visible={this.getStep() === STEP_RESULT}>
                                    <Result
                                        token={this.state.token}
                                        configuration={this.state.selectedConfiguration}
                                        configurations={this.state.config.configurations}
                                        onAgain={this.onAgain}
                                        apiKey={this.state.config.apiKey}
                                        result={this.state.result}
                                    />
                                </Container>
                            </div>
                        </React.Fragment>
                    ) : (
                        <Landing
                            configurations={this.state.config.configurations}
                            configuration={this.state.selectedConfiguration}
                            onChange={this.onConfigurationChange}
                            showSelect={showSelect}
                            options={showSelectOptions}
                            htmlText={landingHtmlText}
                        />
                    )
                }
                <link href={this.state.style} rel="stylesheet"></link>
            </div>
        )
    }
}