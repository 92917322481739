import * as React from 'react';
import { Container } from '../Components/Container';
import { StatusWaiter } from '../Components/StatusWaiter';
import { ApiConfiguration } from '../../helpers/api';

export interface WaiterContainerProps {
    visible: boolean;
    onStatusFinished: (result: any) => void;
    onError: (e: any) => void;
    onGlobalError: (e: any) => void;
    flow: string;
    token: string;
    configuration: ApiConfiguration;
}

export class WaiterContainer extends React.Component<WaiterContainerProps> {
    public render() {
        return (
            <Container visible={this.props.visible}>
                <StatusWaiter
                    waiting={this.props.visible}
                    token={this.props.token}
                    configuration={this.props.configuration}
                    onFinish={this.props.onStatusFinished}
                    onError={this.props.onError}
                    onGlobalError={this.props.onGlobalError}
                    flow={this.props.flow}
                />
            </Container>
        )
    }
}
