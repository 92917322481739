import * as React from 'react';

export interface ContainerProps {
    visible: boolean;
    style?: any;
    fullWidth?: boolean;
}

export interface ContainerState {
    display: boolean;
    opacity: number;
    absolutePosition: boolean;
}

export class Container extends React.Component<ContainerProps, ContainerState> {
    protected timeout = null;
    constructor(props: ContainerProps) {
        super(props);
        this.state = {
            display: props.visible,
            opacity: props.visible ? 1 : 0,
            absolutePosition: props.visible ? false : true,
        }
    }

    public UNSAFE_componentWillReceiveProps(nextProps: ContainerProps) {
        if (nextProps.visible !== this.props.visible) {
            if (this.timeout) {
                clearTimeout(this.timeout);
                this.timeout = null;
            }

            if (nextProps.visible) {
                this.setState({
                    opacity: 1,
                    display: true,
                    absolutePosition: false,
                });
            } else {
                this.setState({
                    opacity: 0,
                    display: true,
                    absolutePosition: true,
                });
                this.timeout = setTimeout(() => {
                    this.setState({
                        opacity: 0,
                        display: false
                    });
                }, 200);
            }
        }
    }

    public render() {
        const style = {
            transition: 'opacity 0.2s linear, left 0.2s linear',
            opacity: this.state.opacity,
            display: this.state.display ? 'block' : 'none',
            position: (this.state.absolutePosition ? 'absolute' : 'relative') as any,
            left: 0,
            top: 0,
            ...this.props.style,
        }
        return <div className={'container' + (this.props.fullWidth ? ' full' : '')} style={style}>{this.props.children}</div>
    }
}