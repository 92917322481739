/**
 * Capitalize text
 * @param input
 */
export function capitalize(input: string): string {
   return input.charAt(0).toUpperCase() + input.slice(1);
}

/**
 * Replace all occurence in string
 * @param input
 * @param search
 * @param replacement
 */
export function replaceAll(input: string, search: string, replacement: string): string {
   return input.replace(new RegExp(search, 'g'), replacement);
}
