import * as React from 'react';
import { WaitingFor } from './WaitingFor';
import { getResult, getState, ApiConfiguration } from '../../helpers/api';
import { capitalize } from '../../helpers/string';

export interface ResultWaiterProps {
    onError: (e: string) => void;
    onGlobalError: (e: string) => void;
    onFinish: (result) => void;
    token: string;
    waiting: boolean;
    flow: string;
    configuration: ApiConfiguration;
}

export interface ResultWaiterState {
}

export class StatusWaiter extends React.Component<ResultWaiterProps, ResultWaiterState> {
    protected interval = null;
    constructor(props: ResultWaiterProps) {
        super(props);

        if (props.waiting) {
            this.startWaiting();
        }
    }

    protected clearInterval() {
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = null;
        }
    }

    protected startWaiting() {
        this.clearInterval();
        this.interval = setInterval(this.handleTick, 1000);
    }

    public UNSAFE_componentWillReceiveProps(nextProps: ResultWaiterProps) {
        if (nextProps.waiting !== this.props.waiting) {
            if (nextProps.waiting) {
                this.startWaiting();
            } else {
                this.clearInterval();
            }
        }
    }

    protected translateKey(key: string): string {
        const map  = {
            'input-file-id-front': 'front image of ID card',
            'input-file-id-back': 'back image of ID card',
            'input-selfie': 'selfie image',
        }

        return map[key] ? map[key] : null;
    }

    protected translateError(input: string, key: string): string {
        const map  = {
            cardNotFound: `We did not found card on ${key ? key : 'image'}.`,
            cardNotValid: `We did not found card on ${key ? key : 'image'}.`,
            mimeTypes: 'Only JPEG or PNG image format is supported.',
            minSize: `${key ? capitalize(key) : 'image'} is too small.`,
            maxSize: `${key ? capitalize(key) : 'image'} is too large.`,
            maxWidth: `${key ? capitalize(key) : 'image'} is too large.`,
            maxHeight: `${key ? capitalize(key) : 'image'} is too large.`,
            minWidth: `${key ? capitalize(key) : 'image'} is too small.`,
            minHeight: `${key ? capitalize(key) : 'image'} is too small.`,
            tooFewFaces: `We did not found face on ${key ? key : 'image'}.`,
            tooManyFaces: `We found more than one face on ${key ? key : 'image'}.`,
            imageNotEnoughSharp: `The ${key ? key : 'image'} is not sharp enough.`,
            imageTooDark: `The ${key ? key : 'image'} is too dark.`,
            imageTooLight: `The ${key ? key : 'image'} is too light.`,
            imageContrastTooLow: `Contrast of ${key ? key : 'image'} is too low.`,
            imageContrastTooHigh: `Contrast of ${key ? key : 'image'} is too high.`,
            cardFieldsMissing: `Card cannot be read properly.`,
        }

        return map[input] ? map[input] : 'Unknown error';
    }

    protected handleTick = () => {
        getState(this.props.configuration, this.props.token, this.props.flow).then((result) => {
            let errors = [];
            for(const i of result.fields) {
                errors = errors.concat(i.errors.map(item => this.translateError(item, this.translateKey(i.key))));
            }

            if (errors.length) {
                this.props.onError(errors.join(' | '));
            } else if (result.finished) {
                getResult(this.props.configuration, this.props.token, this.props.flow)
                .then((result) => {
                    this.props.onFinish(result);
                }).catch((e) => {})
            } else if (result.hasError) {
                // throw global error, that cause restart of token - we dont know why error occured!
                this.props.onGlobalError('Unknown error occured.');
            }

        }).catch((e) => {})

        //{"status":"ok","state":{"finished":false,"fields":[{"type":"image","key":"input-file","valid":true,"errors":[]}]}}
    }

    public render() {
        return (
            <div>
                <WaitingFor text={'Waiting for results.'} />
            </div>
        )
    }
}
