import * as React from 'react';
import * as TJSONEditor from '@json-editor/json-editor';

import 'bootstrap-css-only/css/bootstrap.css';

interface Props {
    schema: any;
    onChange: (value: any) => void;
}

export class JSONEditor extends React.Component<Props> {
    protected ref: HTMLDivElement;
    protected editor: TJSONEditor;

    public componentDidMount() {
        // Set an option during instantiation
        this.editor = new TJSONEditor.JSONEditor(this.ref, {
            schema: this.props.schema,
            disable_collapse: true,
            disable_properties: true,
            disable_edit_json: true,
            compact: true,
            form_name_root: '',
            theme: 'bootstrap4'
        });

        this.editor.on('change', this.onChange);
    }

    public componentWillUnmount() {
        if (this.editor) {
            this.editor.off('change', this.onChange);
        }
    }

    public render() {
        return <div className='json-editor container'>
            {this.props.schema && this.props.schema.htmlText ? <div
                className='htmlText'
                dangerouslySetInnerHTML={{
                    __html: this.props.schema.htmlText
                }}></div> : null}
            <div
                ref={(e) => this.ref = e}
            />
        </div>;
    }

    protected onChange = (value) => {
        this.props.onChange(this.editor.getValue());
    }
}
