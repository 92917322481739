
import * as React from 'react';
import { isMobile } from 'react-device-detect';


export interface TakePhotoProps {
    onPhotoTaken: (blob) => void;
    onClose: () => void;
}

export interface TakePhotoState {
    ready: boolean;
}

export class TakePhoto extends React.Component<TakePhotoProps, TakePhotoState> {
    protected videoDevice;
    protected canvasRef;
    protected videoRef;
    protected stream;

    constructor(props: TakePhotoProps) {
        super(props);
        this.state = {
            ready: false
        };
    }

    public componentDidMount() {
        this.start();
    }

    public componentWillUnmount() {
        if (this.stream) {
            this.stream.getTracks().forEach(track => track.stop());
        }
    }

    protected gotMedia = (mediaStream) => {
        this.stream = mediaStream;
        this.videoRef.srcObject = mediaStream;
        this.videoRef.play();
        this.setState({
            ready: true
        });
    }

    protected start = () => {
        navigator.mediaDevices.getUserMedia({video: { width: 1280, height: 720 }})
        .then(this.gotMedia)
        .catch(() => {
            this.props.onClose();
        })
    }

    protected dataURItoBlob(dataURI) {
        let byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(dataURI.split(',')[1]);
        } else {
            byteString = unescape(dataURI.split(',')[1]);
        }
        // separate out the mime component
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        // write the bytes of the string to a typed array
        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ia], {type:mimeString});
    }

    protected takePhoto = () => {
        if (!this.state.ready || this.videoRef.readyState !== 4) {
            return;
        }
        const context = this.canvasRef.getContext('2d');
        const width = this.videoRef.videoWidth;
        const height = this.videoRef.videoHeight;

        this.canvasRef.width = width;
        this.canvasRef.height = height;

        context.fillStyle = '#FFF';
        context.fillRect(0, 0, width, height);
        context.drawImage(this.videoRef, 0, 0, width, height);

        const uri = this.canvasRef.toDataURL('image/jpeg', 1.0);

        this.props.onPhotoTaken(this.dataURItoBlob(uri))
    }

    public render() {
        const style = {
            width: isMobile ? '80%' : 'auto'
        };
        return (
            <div className='takePhotoOverlay'>
                <div className='takePhoto' style={style}>
                    <video ref={(item) => this.videoRef = item}></video>
                    <div className='canvasWraper'>
                        <canvas style={{visibility: 'hidden'}} ref={(item) => this.canvasRef = item} />
                    </div>
                    <a className='buttonSecondary' onClick={this.props.onClose}>Close</a>
                    <a className='button' onClick={this.takePhoto}>Take picture</a>
                </div>
            </div>
        )
    }
};
